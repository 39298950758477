<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-2 mb-8 black--text">
        Zmiana statusu klienta: <b>{{ clientName }}</b>
      </div>
      <v-row v-if="!isClientBlocked">
        <v-col cols="12">
          <v-textarea
            outlined
            :rows="3"
            auto-grow
            label="Uzasadnienie"
            v-model="blockExplanation"
            placeholder="Wpisz powód nałożenia blokady"
          />
        </v-col>
      </v-row>
    </template>
    <template #submit>
      <v-btn
        v-if="isClientBlocked"
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="unblockClient()"
        @click="unblockClient()"
      >
        Odblokuj klienta
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="blockClient()"
        @click="blockClient()"
      >
        Zablokuj klienta
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data: () => ({
    blockExplanation: ''
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      isProcessing: state => state.client.isProcessing
    }),
    isClientBlocked () {
      return this.dialog.data.action !== 'blockClient'
    },
    clientId () {
      const { client, clientId, id } = this.dialog.item
      return client?.id || clientId || id
    },
    clientName () {
      const { clientName, name } = this.dialog.item
      return clientName || name
    }
  },
  beforeDestroy () {
    this.setClientProcessing(false)
  },
  methods: {
    ...mapActions({
      setClientBlock: 'client/setClientBlock',
      setClientUnblock: 'client/setClientUnblock',
      setClientProcessing: 'client/setClientProcessing'
    }),
    blockClient () {
      this.setClientBlock({
        id: this.clientId,
        blockExplanation: this.blockExplanation || null
      })
      this.blockExplanation = ''
    },
    unblockClient () {
      this.setClientUnblock(this.clientId)
    }
  }
}
</script>
